import React from "react"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import KukeFeature from "../components/kukeFeature"
import MenuItems from "../components/menuItems"
import kukeFeatures from "../json/kuke-features.json"

const FeaturePage = function () {
  return (
    <Layout cur="/features/" stickyHeader>
      <SEO
        title="功能介绍"
        keywords={[
          "约课系统功能",
          "会员管理功能",
          "课程预约功能",
          "在线支付功能",
          "微信小程序约课",
          "课表排期功能",
          "会员卡管理",
          "培训机构管理"
        ]}
        description="酷课约课系统提供完整的会员管理、课程预约、课表排期、在线支付、营销推广等功能模块。支持会员分类管理、课程分类管理、多门店管理、数据统计分析等专业功能，助力培训机构提升运营效率。"
      />

      <div style={{height: 30}}/>
      <MenuItems
        icon="kuke_features/icons/features.png?777"
        iconSize={[84, 134]}
        list={kukeFeatures.map(v => ({ id: v.icon, name: v.title }))}
        title="酷课约课系统有哪些功能？"
        idPre="intro-"
      />
      <KukeFeature />
    </Layout>
  )
}

export default FeaturePage
