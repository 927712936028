import React, { useState } from "react"
import PropTypes from "prop-types"
// import classNames from 'classnames'
import st from "./css/PopVideo.module.scss"
import Svg from "./svg"

const PopVideo = ({ visible, onClose, src }) => {
  if (!visible) return ""

  return (
    <div className={st.wrapper} onClick={onClose} data-typ="close">
      <div className={st.videoWrapper}>
        <div className={st.closeWrapper}>
          <Svg datatyp="close" svgClass={st.closeIcon} id="close" />
        </div>
        <video controls autoPlay width="100%">
          <source
            src={`https://cdn.kukecloud.com/assets/www/assets/video/${src}`}
            type="video/mp4"
          />
          <div>抱歉，你的浏览器不支持HTML5播放器</div>
        </video>
      </div>
    </div>
  )
}

PopVideo.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  src: PropTypes.string,
}

PopVideo.defaultProps = {
  visible: false,
  onClose: () => {},
  src: ``,
}

export default PopVideo
